<template>
  <div>

    <div class="card">
      <div class="card-body">
        <button type="button" style="float: right"  data-bs-toggle="modal" data-bs-target="#createRoleModal" class="btn btn-primary px-5">New Role</button>
        <pagination @search="loadRoles" :data="pagination"></pagination>

        <table id="example" class="table table-hover" style="width:100%">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="role in roles" :key="role.id" style="cursor: pointer;" @click="$router.push({name: 'admin.management.roles.view', params: {id: role.id}})">
              <td>{{role.id}}</td>
              <td>{{role.name}}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th>#</th>
              <th>Name</th>
            </tr>
          </tfoot>
        </table>

        <pagination @search="loadRoles" :data="pagination"></pagination>
      </div>
    </div>

    <!-- MODALS -->

    <div class="modal fade" id="createRoleModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create Role</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 mb-2">
                <label for="roleName" class="form-label">Role Name</label>
                <input type="text" class="form-control" id="roleName" v-model="role.name">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeCreateRoleModal'>Close</button>
            <submit-button type="button" :background="'btn btn-primary'" :clicked="creating" @click="createRole" text="Save changes"></submit-button>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
/* global $ */
import axios from 'axios';
import Pagination from '../../../../components/Pagination.vue';
import SubmitButton from '../../../../components/SubmitButton.vue';
export default {
  name: 'admin.management.roles',
  components: {
    Pagination,
    SubmitButton
  },
  data(){
    return {
      roles: [],
      pagination: {
        page: 0,
        showing: 0,
        total: 0,
        lastPage: 0
      },
      role: {
        name: ""
      },
      creating: false
    }
  },
  mounted(){
    this.loadRoles();
  },
  methods: {
    loadRoles(page = 1){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/roles?page=${page}`)
      .then(response => {
        this.roles = response.data.roles;
        this.pagination = response.data.pagination
      })
      .catch(error => {
        this.$error('Failed to load role.', error);
      });
    },
    createRole(){
      if(this.role.name.length < 1){
        this.$error("Please enter a valid name!");
        return;
      }
      this.creating = true;
      axios.put(`${process.env.VUE_APP_API_URL}/v1/roles`, this.role)
      .then(() => {
        $('#closeCreateRoleModal').click();
        this.$success("Created new role");
        this.creating = false;
        this.role = {
          name: ""
        }
        this.loadRoles(this.pagination.page);
      })
      .catch(error => {
        this.$error("Failed to create new role", error);
        this.creating = false;
      });
    }
  }
}
</script>